import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`Deload Week`}</em></strong></p>
    <p>{`DB Bench Press 3×12`}</p>
    <p>{`DB Rows`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time:`}</p>
    <p>{`20-Calorie Row`}</p>
    <p>{`20-Ring Dips`}</p>
    <p>{`20-Alternating DB Snatch (50/35)`}</p>
    <p><em parentName="p">{`*`}{`Only 10:30 & 12:00 classes today.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      